
import { api } from "@/api/api";
import { ApiGetCourseParticipantDto, ApiGetMinSideCourseDto } from "@/api/generated/Api";
import TheCourseDetailsModal from "@/components/dashboard/TheCourseDetailsModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { StatusType } from "@/shared/enums/courseApplicationStatus.enums";
import { CourseStatus } from "@/shared/enums/courseStatus.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { getIsoDate } from "@/shared/helpers/dateHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { filterCoursesByStatuses } from "@/shared/helpers/filterHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { randomNumber } from "@/shared/helpers/otherHelpers";
import { CalendarColors } from "@/shared/utils/colors";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export interface VuetifyCalendarEvent {
  name: string;
  start: Date;
  end: Date;
  color?: string;
  timed: boolean;
  id: string; // use to find clicked element faster
}

export default defineComponent({
  name: "TheCalendar",
  components: { BaseModal, TheCourseDetailsModal },
  setup() {
    const view = ref(types[1]);
    const showCourseDetailsModal = ref(false);
    const modalCourseId = ref(0);
    const modalClassName = ref("");
    const course = ref<ApiGetMinSideCourseDto>();
    const selectedEvent = ref<VuetifyCalendarEvent>();

    const vuetifyCalendarEvents = ref<VuetifyCalendarEvent[]>([]);

    const calendarValue = ref<string>(getIsoDate(new Date()));

    const showModal = async (event: { event: VuetifyCalendarEvent }) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        course.value = (await api.minside.getCourseByIdAsync(+event.event.id)).data;
        modalCourseId.value = +event.event.id;
        modalClassName.value = event.event.name;
        selectedEvent.value = event.event;
        showCourseDetailsModal.value = true;
      });
    };

    const fetchCalendarEvents = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const courses = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data;
        const filteredCoursesByStatus = filterCoursesByStatuses(courses, allowedCourseStatuses, "course"); // FIXME improve this
        const filteredCoursesByUserStatus = filterCoursesByStatuses(
          filteredCoursesByStatus,
          allowedUserStatuses,
          "user"
        ) as ApiGetCourseParticipantDto[]; // FIXME improve this
        const courseIds = filteredCoursesByUserStatus.map((course) => course.courseId); // FIXME improve this

        vuetifyCalendarEvents.value = (
          await Promise.all(
            courseIds.map(async (courseId) => ({
              courseId,
              course: (await api.minside.getCourseByIdAsync(courseId)).data,
            }))
          )
        ).flatMap(
          ({ courseId, course: { plan, externalTitle } }) =>
            plan?.schedules?.flatMap(({ start, end, title }) => ({
              id: courseId.toString(),
              name: `${title} - ${externalTitle}`,
              start: new Date(start),
              end: new Date(end),
              color: CalendarColors[randomNumber(0, CalendarColors.length - 1)],
              timed: true,
            })) ?? []
        );
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await fetchCalendarEvents();
      });
    });

    return {
      calendarValue,
      types,
      view,
      vuetifyCalendarEvents,
      course,
      modalCourseId,
      modalClassName,
      selectedEvent,
      showCourseDetailsModal,
      weekdays,
      showModal,
      isMobile,
    };
  },
});

const types = [
  {
    value: "week",
    label: "Uke",
  },
  {
    value: "month",
    label: "Måned",
  },
  {
    value: "day",
    label: "Dag",
  },
];

const weekdays = [1, 2, 3, 4, 5, 6, 0];
const allowedCourseStatuses = [CourseStatus.Planlagt, CourseStatus.Åpnet];
const allowedUserStatuses = [StatusType.Påmeldt];
