
import { api } from "@/api/api";
import { ApiGetMinSideCourseDto } from "@/api/generated/Api";
import TheBaseCourseForm from "@/components/dashboard/TheBaseCourseForm.vue";
import { formatLocalizedDate, formatTime } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import BaseInfoField from "../shared/field/BaseInfoField.vue";
import { VuetifyCalendarEvent } from "./TheCalendar.vue";

export default defineComponent({
  name: "TheCourseDetailsModal",
  components: { TheBaseCourseForm, BaseInfoField },
  emits: ["update"],
  props: {
    courseId: {
      type: Number,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    selectedEvent: {
      type: Object as PropType<VuetifyCalendarEvent>,
      required: true,
    },
  },
  setup(props) {
    const courseValues = ref<ApiGetMinSideCourseDto>();
    const courseLocationValues = ref();

    const modalHeader = computed(() => props.course.externalTitle ?? props.course?.courseName);

    const getCourseLocation = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!props.course || !props.course.courseLocation?.areaId) {
          return;
        }

        const area = (await api.area.getAreaByIdAsync(props.course.courseLocation?.areaId)).data;

        if (!props.course.courseLocation.locationId) {
          return { area: area.place };
        }
        const location = (
          await api.area.getLocationAsync(props.course.courseLocation.areaId, props.course.courseLocation.locationId)
        ).data;

        if (!props.course.courseLocation.roomId) {
          courseLocationValues.value = {
            area: area.place,
            street: location.street,
            postalCode: location.postalCode,
            postalArea: location.postalArea,
          };
          return;
        }
        const room = (
          await api.area.getRoomAsync(
            props.course.courseLocation.areaId,
            props.course.courseLocation.locationId,
            props.course.courseLocation.roomId
          )
        ).data;

        courseLocationValues.value = {
          area: area.place,
          street: location.street,
          postalCode: location.postalCode,
          postalArea: location.postalArea,
          roomName: room.name,
          floorNumber: room.floorNumber,
        };
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await getCourseLocation();
      });
    });

    return {
      modalHeader,
      courseValues,
      courseLocationValues,
      formatTime,
      formatLocalizedDate,
    };
  },
});
