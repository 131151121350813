
import { api } from "@/api/api";
import { ApiGetCustomerDto } from "@/api/generated/Api";
import TheCalendar from "@/components/dashboard/TheCalendar.vue";
import TheBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "DashboardPage",
  components: { TheBreadCrumbs, TheCalendar },
  setup() {
    const store = useStore<StoreState>();
    const customerId = computed(() => store.state.customer.customer?.id);
    const currentUser = ref<ApiGetCustomerDto>();

    const fetchCustomer = async () => {
      currentUser.value = (await api.minside.getCustomerPersonCurrentAsync()).data;
    };

    watch(customerId, (custId) => {
      runStoreAction(store, StoreModules.Course, StoreActions.CourseActions.FetchRoles, custId);
      fetchCustomer();
    });

    return {
      currentUser,
    };
  },
});
