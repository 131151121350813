var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pb-2",
    attrs: {
      "elevation": 2
    }
  }, [_vm.showCourseDetailsModal ? _c('BaseModal', [_vm.course ? _c('TheCourseDetailsModal', {
    attrs: {
      "courseId": _vm.modalCourseId,
      "className": _vm.modalClassName,
      "course": _vm.course,
      "selectedEvent": _vm.selectedEvent
    },
    on: {
      "close": function ($event) {
        _vm.showCourseDetailsModal = false;
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.$refs.calendar ? _c('v-toolbar', {
    attrs: {
      "flat": "",
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "x-small": _vm.isMobile,
      "small": !_vm.isMobile,
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.calendar.prev();
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-chevron-left ")])], 1), _c('v-toolbar-title', {
    staticClass: "flex-grow-1 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]), _c('v-menu', {
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-6",
          attrs: {
            "outlined": "",
            "data-cy": "timeSpan"
          }
        }, 'v-btn', attrs, false), on), [_c('span', [_vm._v(_vm._s(_vm.view.label))]), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" mdi-menu-down ")])], 1)];
      }
    }], null, false, 1521796945)
  }, [_c('v-list', _vm._l(_vm.types, function (type) {
    return _c('v-list-item', {
      key: type.value,
      on: {
        "click": function ($event) {
          _vm.view = type;
        }
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(type.label))])], 1);
  }), 1)], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "x-small": _vm.isMobile,
      "small": !_vm.isMobile,
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.calendar.next();
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-chevron-right ")])], 1)], 1) : _vm._e(), _c('v-sheet', {
    attrs: {
      "height": "85vh"
    }
  }, [_c('v-calendar', {
    ref: "calendar",
    attrs: {
      "weekdays": _vm.weekdays,
      "type": _vm.view.value,
      "events": _vm.vuetifyCalendarEvents,
      "event-overlap-threshold": 30,
      "color": "primary",
      "locale": "no"
    },
    on: {
      "click:event": _vm.showModal
    },
    model: {
      value: _vm.calendarValue,
      callback: function ($$v) {
        _vm.calendarValue = $$v;
      },
      expression: "calendarValue"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }