var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TheBaseCourseForm', {
    attrs: {
      "headline": _vm.modalHeader
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm.courseLocationValues ? _c('v-row', [_c('BaseInfoField', {
    attrs: {
      "value": _vm.courseLocationValues.area,
      "label": "Kurssted",
      "cols": 6
    }
  }), _c('BaseInfoField', {
    attrs: {
      "value": _vm.courseLocationValues.street,
      "label": "Adresse",
      "cols": 6
    }
  })], 1) : _vm._e(), _vm.courseLocationValues ? _c('v-row', [_c('BaseInfoField', {
    attrs: {
      "value": _vm.courseLocationValues.postalCode,
      "label": "Postnr",
      "cols": 6
    }
  }), _c('BaseInfoField', {
    attrs: {
      "value": _vm.courseLocationValues.postalArea,
      "label": "Poststed",
      "cols": 6
    }
  })], 1) : _vm._e(), _vm.courseLocationValues ? _c('v-row', [_c('BaseInfoField', {
    attrs: {
      "value": _vm.courseLocationValues.roomName,
      "label": "Romnavn",
      "cols": 6
    }
  }), _c('BaseInfoField', {
    attrs: {
      "value": _vm.courseLocationValues.floorNumber,
      "label": "Etasje",
      "cols": 6
    }
  })], 1) : _vm._e(), _c('v-row', [_c('BaseInfoField', {
    attrs: {
      "value": _vm.formatLocalizedDate(_vm.selectedEvent.start),
      "label": "Dato",
      "cols": 6
    }
  }), _c('BaseInfoField', {
    attrs: {
      "value": ((_vm.formatTime(_vm.selectedEvent.start)) + " - " + (_vm.formatTime(_vm.selectedEvent.end))),
      "label": "Klokkeslett",
      "cols": 6
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }