var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('TheBreadCrumbs'), _c('TheCalendar')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }