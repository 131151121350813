var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-container', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.headline))])]), _c('v-card-text', [_vm._t("default")], 2), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "primary": "",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Lukk")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }