
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheBaseCourseForm",
  props: {
    headline: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
